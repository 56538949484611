import moment from 'moment'
// import Router from 'vue-router'
let utils = {
  T(str, lang = 'en') {
    if (str) str = str.replace(/-/g, '/')
    let timestamp = new Date(str || '');
    let en = lang == 'en'
    // var mistiming = Math.round(new Date() / 1000) - timestamp;
    var mistiming = Math.round((Date.now() - timestamp) / 1000);
    var arrr = en ? [' tears ', ' months ', ' weeks ', ' days ', ' hours ', ' minutes ', ' seconds '] : ['年', '个月', '星期', '天', '小时', '分钟', '秒'];
    var s = en ? ['Yesterday'] : ['昨天'];
    var arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
    for (var i = 0; arrn.length; i++) {
      var inm = Math.floor(mistiming / arrn[i]);
      if (inm != 0) {
        if (i == 3 && inm == 1) return s + ''
        if (i <= 3 && inm >= 1) {
          return moment(new Date(str)).format(en ? 'YYYY MMM DD HH:MM' : 'YYYY-MM-DD HH:MM')
        }
        return inm + arrr[i] + (en ? 'ago' : '前');
      }
    }
  },
  getLength: function (str) {
    if (!str) { return 0; }
    var len = 0;
    for (var i = 0; i < str.length; i++) {
      len += str.charCodeAt(i) > 127 ? 2 : 1;
    }
    return len
  },
  formatTime(time, lang) {
    let isus = (lang || 'en') == 'en'
    if (!time) return '--'
    return isus ? moment(time).format('MMM Do  HH:mm:ss') : time
  },
  subString: function (str, len) {
    if (!str || !len) { return ''; }
    var build = "";
    for (var i = 0; i < str.length && len > 0; i++) {
      build += str.substr(i, 1);
      len -= str.charCodeAt(i) > 127 ? 2 : 1;
    }
    if (build.length < str.length)
      build += "...";
    return build;
  },
  setCookie: function (name, value, days = 1) { //两个参数，一个是cookie的名子，一个是值
    var d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";path=/;" + expires;
  },
  getCookie: function (cname) { //取cookies函数
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
  },
  delCookie: function (name) //删除cookie
  {
    utils.setCookie(name, "", -1);
  },
  getUrlParam(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let url = window.location.href.split('#')[0]
    let search = url.split('?')[1]
    if (search) {
      var r = search.substr(0).match(reg)
      if (r !== null) return unescape(r[2])
      return null
    } else {
      return null
    }
  },
  getQuery() {
    let object = {};
    let url = window.location.search.replace('?', '').split('&')
    url.forEach(u => {
      if (u) {
        let key = u.split('=')[0]
        if (key) {
          object[key] = u.match(new RegExp('(^|&)' + key + '=([^&]*)(&|$)'))[2];
        }
      }
    })
    return object;
  },
  //对象排序
  sortRank(key) {
    return (a, b) => {
      return a[key] - b[key];
    }
  },
  changeUnit1(num) {
    let is_negative = false
    if (num < 0) {
      num = -num
      is_negative = true
    }
    let str = ''
    if (num > 1000000000) {
      str = num / 1000000000
      if (Number.isInteger(str)) {
        str = num / 1000000000 + 'B'
      } else {
        str = parseFloat(num / 1000000000).toFixed(2) + 'B'
      }
    } else if (num > 1000000) {
      str = num / 1000000
      if (Number.isInteger(str)) {
        str = num / 1000000 + 'M'
      } else {
        str = parseFloat(num / 1000000).toFixed(2) + 'M'
      }
    } else if (num > 99999) {
      str = num / 1000
      if (Number.isInteger(str)) {
        str = num / 1000 + 'K'
      } else {
        str = parseFloat(num / 1000).toFixed(2) + 'K'
      }
    } else if (num > 999) {
      str = num / 1000
      if (Number.isInteger(str)) {
        str = num / 1000 + 'K'
      } else {
        str = parseFloat(num / 1000).toFixed(2) + 'K'
      }
    } else {

      str = this.changeUnit(num)
      // str = Number.isInteger(num) ? num : parseFloat(num).toFixed(2)
    }
    return is_negative ? '-' + str : str
  },
  changeUnit(num, s = '', is_float) {
    if (num === null || num === undefined || num === '') return '--'
    if (num == 0 || isNaN(num)) return 0
    if (num < 0) return s + parseFloat(num).toFixed(2)

    if (/e|E/i.test(num)) { //科学计数转数字
      return s + (Number(num)).toFixed(18).replace(/\.?0+$/, "");
    }

    if (num < 1) return s + parseFloat(num)

    let str = num % 1
    if (str > 0) {
      str = parseFloat(num).toFixed(2)
    } else {
      str = is_float ? parseFloat(num).toFixed(2) : parseInt(num)
    }
    return s + (str + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
  },
  random(min = 1, max = 12) {
    return parseInt((Math.random() * (max - min)) + min)
  },
  deepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  moneyUnit(num, lang) {
    if (!num) return '--'
    if (lang == 'zh') return num
    if (num == '未披露') return 'Undisclosed'
    num = num.replace('万美元', '').replace('亿美元', '')
    num = this.multiply(num, 0.01)
    num = '$' + num + 'M'
    return num
  },
  getRounds(key, lang) {
    if (lang == 'zh') return key
    let rounds = {
      'A++轮': 'Series A3 Round',
      'A+轮': 'Series A2 Round',
      'A轮': 'Series A Round',
      'B++轮': 'Series B3 Round',
      'B+轮': 'Series B2 Round',
      'B轮': 'Series B Round',
      'C++轮': 'Series C3 Round',
      'C+轮': 'Series C2 Round',
      'C轮': 'Series C Round',
      'D++轮': 'Series D3 Round',
      'D+轮': 'Series D2 Round',
      'D轮': 'Series D Round',
      'E+轮': 'Series E2 Round',
      'E轮': 'Series E Round',
      'F轮': 'Series F Round',
      'G+轮': 'Series G2 Round',
      'G轮': 'Series G Round',
      'H轮': 'Series H Round',
      'ICO众筹': 'ICO',
      'IPO上市': 'IPO',
      'Pre-A+轮': 'Pre-A Round',
      'Pre-A轮': 'Pre-A Round',
      'Pre-B轮': 'Pre-B Round',
      'Pre-C轮': 'Pre-C Round',
      'Pre-F轮': 'Pre-F Round',
      'Pre-IPO': 'Pre-IPO',
      '并购': 'M&A',
      '定向增发': 'Private Placement',
      '股权融资': 'Equity Financing',
      '股权转让': 'Equity Transfer',
      '天使+': 'Angel Round',
      '天使+轮': 'Angel Round',
      '天使轮': 'Angel Round',
      '债权融资': 'Debt Financing',
      '战略融资': 'Strategic Financing',
      '种子轮': 'Seed Round',
      '私募': 'Private Sale',
      '众筹融资': 'Public Sale',
    }
    return rounds[key] || '--'
  },
  replaceTag(str) {
    if (!str) return ''
    return str.replace(/\<p>|\<\/p>|\<strong>|\<\/strong>|\s\<a>|\<\/a>|\s\<li>|\<\/li>|\s\<ul>|\<\/ul>/gi, '')
  },
  replaceUrl(url) {
    if (!url) return ''
    return url.indexOf('http') > -1 ? url : ('https://' + url)
  },
  multiply(a, b = 100) {
    if (a === null) return null
    if (!a || !b) return
    let stra = a.toString()
    let strb = b.toString()
    let lenA = stra.indexOf('.') < 0 ? 0 : (stra.length - stra.indexOf('.') - 1)
    let lenB = strb.indexOf('.') < 0 ? 0 : (strb.length - strb.indexOf('.') - 1)
    let max = Math.max(lenA, lenB)
    return (parseFloat(a * b).toFixed(max) - 0)
  },
  formatNum(add, reduce, s, num) {
    let m = Math.pow(10, num);
    let res = s == '+' ? (add * m + reduce * m) / m : (add * m - reduce * m) / m;
    return Math.round(res * m) / m;
  },
  titleCase(str) {
    let newStr = ''
    if (str == 'eoa') {
      newStr = 'EOA'
    } else {
      newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
    }
    return newStr;

  },
  linkUrl(value) {
    let copyInput = document.createElement('input');//创建input元素
    document.body.appendChild(copyInput);//向页面底部追加输入框
    copyInput.setAttribute('value', value);//添加属性，将url赋值给input元素的value属性
    copyInput.select();//选择input元素
    document.execCommand("Copy");//执行复制命令
    //复制之后再删除元素，否则无法成功赋值
    copyInput.remove();//删除动态创建的节点
  },
  replaceText(msg) {
    msg = msg.replace(/((https):\/\/[-a-zA-Z0-9+&@#\/%?=~_|!:,.;]*)/g, "<a class='text-twitter-link' target='blank' href=\"$1\">$1</a>")
    msg = msg.replace(/@([-a-zA-Z0-9+_]*)/g, "<a class='text-twitter-link' target='blank' href=\"https://twitter.com/$1\">@$1</a>")
    msg = msg.replace(/#([-a-zA-Z0-9+_]*)/g, "<a class='text-twitter-link' target='blank' href=\"https://twitter.com/search?q=$1\">#$1</a>")
    return msg
  },
  getCount(list, day = 0) {
    if (!list.length) return 0
    let count = list.slice(day * -1).reduce((m, n) => { return (m - 0) + (n - 0) })
    return this.changeUnit1(count || 0)
  },
  deepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
}

utils.install = (Vue, a) => {
  Vue.prototype.utils = utils
};

export default utils