// https://nuxtjs.org/docs/directory-structure/store/

export const state = () => ({
  ip: "",
  ips:'{}', 
  notfound:false,
  showBanner: true,//修改路由跳转 首页banner闪烁问题
  h5: false,
  theme: 'dark',
  lang: 'en',
  menuActiveKey: [],
  gamefiData: {},
  trendIndex: '',
  rankgameIndex: '',
  newgameIndex: '',
  //游戏列表  自定义弹框 下面选中的
  custom: "{}",
  //游戏列表  自定义弹框 上面面选中的
  btn: "[]",
  paramsStatus: { black_chain: '', tag: '' },
  whaleData: { game_count: 0, whale_count: 0 },
  gameInfo: { tags: [], screenshots: [], protocols: [] },
  tagData: {
    'card': 'Card',
    'rpg': 'RPG',
    'moba': 'MOBA',
    'idie': 'IdIe',
    'racing': 'Racing',
    'strategy': 'Strategy',
    'simulation': 'Simulation',
    'sandbox': 'Sandbox',
    'fps': 'FPS',
    'fighting': 'Fighting',
    'platform': 'Platform',
    'collectible': 'Collectible',
    'tower-dfense': 'Tower Dfense',
    'pvp': 'PvP',
    'pve': 'PvE',
    'arcade': 'Arcade',
    'casual': 'Casual',
    'building': 'Building',
    'breeding': 'Breeding',
    'sports': 'Sports',
    'aciton': 'Aciton',
  }
})

export const getters = {
  h5: state => state.h5,
  showBanner: state => state.showBanner,
  whaleData: state => state.whaleData,
  lang: state => state.lang,
  theme: state => state.theme,
  menuActiveKey: state => state.menuActiveKey,
  gamefiData: state => state.gamefiData,
  trendIndex: state => state.trendIndex,
  rankgameIndex: state => state.rankgameIndex,
  custom: state => state.custom,
  btn: state => state.btn,
  gameInfo: state => state.gameInfo,
  paramsStatus: state => {
    let paramsStatus = JSON.parse(localStorage.getItem('paramsStatus') || '{}')
    return JSON.parse(JSON.stringify(paramsStatus))
  },
  ip: state => state.ip,
  ips: state => state.ips,
  notfound: state => state.notfound,
}

export const mutations = {
  setWhaleData(state, whaleData) {
    state.whaleData = whaleData
  },
  setTheme(state, theme) {
    state.theme = theme
  },
  setPlatform(state, h5) {
    state.h5 = h5
  },
  setShowBanner(state, showBanner) {
    state.showBanner = showBanner
  },
  setLang(state, lang) {
    state.lang = lang
  },
  setMenuActiveKey(state, key) {
    state.menuActiveKey = key
  },
  setGamefiData(state, key) {
    state.gamefiData = key
  },
  setTrendIndex(state, key) {
    state.trendIndex = key
  },
  setRankgameIndex(state, key) {
    state.rankgameIndex = key
  },
  setCustom(state, key) {
    state.custom = key
  },
  setBtn(state, key) {
    state.btn = key
  },
  setGameInfo(state, key) {
    state.gameInfo = key
  },
  setIp(state, key) {
    state.ip = key
  },
  setIp(state, key) {
    state.ips = key
  },
  setNotfound(state, key) {
    state.notfound = key
  },
  goNotFound(state, key){
    
  },
  setParamsStatus(state, paramsStatus) {
    let params = JSON.parse(localStorage.getItem('paramsStatus')) || {}
    state.paramsStatus = {
      ...params, ...paramsStatus
    }
    localStorage && localStorage.setItem('paramsStatus', JSON.stringify(state.paramsStatus))
  }
}