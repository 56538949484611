import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _28149c46 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _7c998f60 = () => interopDefault(import('../pages/airdrops/index.vue' /* webpackChunkName: "pages/airdrops/index" */))
const _b20601d2 = () => interopDefault(import('../pages/event-calendar/index.vue' /* webpackChunkName: "pages/event-calendar/index" */))
const _22c0dfce = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _ebc03ae8 = () => interopDefault(import('../pages/nft/index.vue' /* webpackChunkName: "pages/nft/index" */))
const _08f25556 = () => interopDefault(import('../pages/notfound/index.vue' /* webpackChunkName: "pages/notfound/index" */))
const _f5f6013e = () => interopDefault(import('../pages/research/index.vue' /* webpackChunkName: "pages/research/index" */))
const _bb44551a = () => interopDefault(import('../pages/social/index.vue' /* webpackChunkName: "pages/social/index" */))
const _63507339 = () => interopDefault(import('../pages/strategy/index.vue' /* webpackChunkName: "pages/strategy/index" */))
const _7a63645e = () => interopDefault(import('../pages/submit/index.vue' /* webpackChunkName: "pages/submit/index" */))
const _2722793c = () => interopDefault(import('../pages/trends/index.vue' /* webpackChunkName: "pages/trends/index" */))
const _2b7cf2ae = () => interopDefault(import('../pages/social/check.vue' /* webpackChunkName: "pages/social/check" */))
const _b89da12e = () => interopDefault(import('../pages/social/kol.vue' /* webpackChunkName: "pages/social/kol" */))
const _68ab5eca = () => interopDefault(import('../pages/social/rank.vue' /* webpackChunkName: "pages/social/rank" */))
const _ea67ba8e = () => interopDefault(import('../pages/games/_id.vue' /* webpackChunkName: "pages/games/_id" */))
const _130ebde4 = () => interopDefault(import('../pages/guilds/_id.vue' /* webpackChunkName: "pages/guilds/_id" */))
const _69715c06 = () => interopDefault(import('../pages/investor/_id.vue' /* webpackChunkName: "pages/investor/_id" */))
const _be56767e = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _33501d1a = () => interopDefault(import('../pages/rank/_id.vue' /* webpackChunkName: "pages/rank/_id" */))
const _66b8203e = () => interopDefault(import('../pages/strategy/_id.vue' /* webpackChunkName: "pages/strategy/_id" */))
const _70329bf8 = () => interopDefault(import('../pages/studio/_id.vue' /* webpackChunkName: "pages/studio/_id" */))
const _40d550e4 = () => interopDefault(import('../pages/subject/_id.vue' /* webpackChunkName: "pages/subject/_id" */))
const _69ac5595 = () => interopDefault(import('../pages/trend/_id.vue' /* webpackChunkName: "pages/trend/_id" */))
const _4063b521 = () => interopDefault(import('../pages/whale/_id.vue' /* webpackChunkName: "pages/whale/_id" */))
const _28d9dcc2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _28149c46,
    name: "about"
  }, {
    path: "/airdrops",
    component: _7c998f60,
    name: "airdrops"
  }, {
    path: "/event-calendar",
    component: _b20601d2,
    name: "event-calendar"
  }, {
    path: "/news",
    component: _22c0dfce,
    name: "news"
  }, {
    path: "/nft",
    component: _ebc03ae8,
    name: "nft"
  }, {
    path: "/notfound",
    component: _08f25556,
    name: "notfound"
  }, {
    path: "/research",
    component: _f5f6013e,
    name: "research"
  }, {
    path: "/social",
    component: _bb44551a,
    name: "social"
  }, {
    path: "/strategy",
    component: _63507339,
    name: "strategy"
  }, {
    path: "/submit",
    component: _7a63645e,
    name: "submit"
  }, {
    path: "/trends",
    component: _2722793c,
    name: "trends"
  }, {
    path: "/social/check",
    component: _2b7cf2ae,
    name: "social-check"
  }, {
    path: "/social/kol",
    component: _b89da12e,
    name: "social-kol"
  }, {
    path: "/social/rank",
    component: _68ab5eca,
    name: "social-rank"
  }, {
    path: "/games/:id?",
    component: _ea67ba8e,
    name: "games-id"
  }, {
    path: "/guilds/:id?",
    component: _130ebde4,
    name: "guilds-id"
  }, {
    path: "/investor/:id?",
    component: _69715c06,
    name: "investor-id"
  }, {
    path: "/news/:id",
    component: _be56767e,
    name: "news-id"
  }, {
    path: "/rank/:id?",
    component: _33501d1a,
    name: "rank-id"
  }, {
    path: "/strategy/:id",
    component: _66b8203e,
    name: "strategy-id"
  }, {
    path: "/studio/:id?",
    component: _70329bf8,
    name: "studio-id"
  }, {
    path: "/subject/:id?",
    component: _40d550e4,
    name: "subject-id"
  }, {
    path: "/trend/:id?",
    component: _69ac5595,
    name: "trend-id"
  }, {
    path: "/whale/:id?",
    component: _4063b521,
    name: "whale-id"
  }, {
    path: "/",
    component: _28d9dcc2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
