import Vue from 'vue';
import http from './utils/http';
Vue.use(http);

export default (context) => {
  // console.log(context.req.headers.referer)
  // console.log(process.env)
  // process.env.BASE_URL
  // let ua = process.server ? context.req.headers['user-agent'] : navigator.userAgent
  // let h5 = /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(ua)
  // context.store.commit('setPlatform', h5)
  // context.h5 = h5
  if (process.server) { 
    // let referer = context.req.headers.referer || ''
    // http.baseUrl = referer.indexOf('www.') >= 0 ? 'www.mymetadata.io' : 'mymetadata.io'
  }
  context.$http = http
}