module.exports = {
  share: 'Share',
  copy_success: 'Copy Success',
  share_tiwtter: 'Share To Twitter',
  copy_link: 'Copy Link',
  share_poster: 'Share Poster',
  com: {
    view_more: 'View More',
    empty: 'No data.',
    view_all: 'View all',
    hour: 'H',
    day: 'D',
    over: 'OVER',
    more: 'More',
    pagesize: 'Rows',
    all: 'All',
    alltabs: 'All Chains',
    alltags: 'All Tags',
  },
  menu: {
    trend: 'Trending Games',
    home: 'Home',
    rank: 'Rankings',
    event_calendar: 'Event Calendar',
    news: 'News',
    strategy: 'Game Guide',
    gamefi: 'GameFi Reports',
    research: 'Research',
    about: 'About',
    lang: 'Dark Mode',
    search: 'Search',
    whale: 'Whales',

  },
  home: {
    kline_title: 'GameFi Market Data',
    kline_sub_title: 'The latest summary of all on-chain Gamefi data',
    news_title: 'News',
    strategy_title: 'Game Guide',
    rank_active_user: 'Active User Growth',
    rank_holder_token: 'Active Wallet Growth',
    rank_increase_list: 'Top Gainers',
    rank_decline_list: 'Top Losers',
    rank_latest_collection: 'New Games',

    col_name: 'Name',
    col_chain: 'Chain',
    col_socail: 'Community',
    col_active_24: 'Users 24H',
    col_holders_24: 'Addresses 24H',
    col_latest: 'Updated',
    col_price_24: 'Price($) 24H',
    col_total: 'Total',
  },
  newgame: {
    testing: 'In Beta',
    financing: 'Recently Financed ',
    all:'Last Update',
    coming: 'Coming Soon',
    recommend:'Recommend',
    title: 'New Games',
    sub_title: 'The latest GameFi projects featured by the platform editor'
  },

  trend: {
    title: 'Trending Games',
    sub_title1: 'Discover trending GameFi projects by tracking ',
    sub_title2: 'Twitter KOLs and',
    sub_title3: 'game guilds/VCs.',
    follower: 'Most KOLs Following',
    hot: 'Most KOLs Discussing',
    investor: 'Latest VC Investments',
    kol_follower: '',
    table_follower: 'Follow List',
    table_hot: 'Hot',
    kol_title: 'Most KOLs Following',
    kol_sub_title: 'Know how many Twitter KOLs are following the game',
    hot_title: 'Most KOLs Discussing',
    hot_sub_title: 'Analysis Twitter KOLs’ tweets and rank those games mentioned by them based on the number of tweets',
    investor_title: 'Latest VC Investments',
    investor_sub_title1: 'Discover Alpha GameFi projects by tracking ',
    investor_sub_title2: ' VCs’ financial events ',
    investor_time: 'Time',
    investor_game: 'Game',
    investor_investor: 'Investors',
    investor_round: 'Round',
    investor_raised: 'Money Raised',
    investor_link: 'Link',
  },
  ido: {
    hot: 'Community',
    total: 'Total Supply',
    start_time: 'Start Time',
    end_time: 'End Time',
    preview: 'Upcoming',
    ongoing: 'Ongoing',
    finished: 'End',
    price: 'Price',
    join: 'Join in',
    whitelist: 'White List',
    tba: 'TBA',
  },
  alpha: {
    alpha_active_user: 'Active User Growth(24H)',
    alpha_holder_token: 'Active Wallet Growth(24H)',
    alpha_new_active_user: 'New Active Users(24H)',
    alpha_new_holder_token: 'New Addresses(24H)',
    alpha_increase: 'Top Gainers',
    alpha_decline: 'Top Losers',
    title: 'Alpha Games',
    sub_title1: 'Discover Alpha GameFi projects by tracking ',
    sub_title2: 'games’ On-Chain data.',
    sub_title3: 'games’ Token prices',
  },
  airdrop: {
    title: 'Airdrops',
    sub_title1: 'Discover airdrop opportunities by aggregating ',
    sub_title2: 'IDO platforms, tracking ',
    sub_title3: 'games’ official Twitter accounts',
  },
  whaleTabs: {
    tab1: 'Whales Movement List',
    tab2: 'Giant Whale Trading',
  },
  whale: {
    time: 'Updated',
    title: 'Whales Tracker',
    sub_title1: 'Track movements of  ',
    sub_title2: ' games by monitoring ',
    sub_title3: ' whales’ addresses',
    whale_growth: 'Whales Growth(24H)',
    whale_increase: 'Whales Overweight List (24H)',
    whale_decline: 'Whales Underweight List (24H)',
    col_name: 'Game',
    col_growth_new: 'New Whales',
    col_growth_change: 'Holding Chg',
    col_increase_addcount: 'Whales Chg',
    col_increase_addprecent: 'Increasing Proportion',
    col_decline_minuscount: 'Whales Chg',
    col_decline_minusprecent: 'Declining Proportion',
    tips: 'Whales Definition: Hold greater than or equal to 0.1% total supplied tokens of one project',
    card_address: 'Number of Whales Addresses',
    card_address_precent: 'Proportion of Total Addresses',
    card_token: 'Tokens Holden by Whales',
    card_token_precent: 'Proportion of Total Supply',
    list_title: 'List of Whales',
    pie_type: 'Distribution of Whales Types',
    pie_assets: 'Distribution of Total Assets',
    pie_token: 'Other Tokens Holden by Whales',
    col_whale_add: 'Address',
    col_assets: 'Holdings',
    col_precent: 'Ratio',
    col_type: 'Type',
    col_change: 'Change',
    pie_assets_title1: 'The number of whales holding <br>',
    pie_assets_title2: 'in their total assets',
    pie_token_title1: 'The number of whales holding ',
    pie_token_title2: '',

  },
  nft: {
    sub_title: "Rank upcoming NFT projects by Discord's active users",
    col_name: 'Name',
    col_user: 'Users',
    col_active_user: 'Active Users',
    col_community: 'Community',
    col_start_time: 'Start Time',
    col_mint_price: 'Mint Price',
    col_links: 'Links',
    new: 'New',
    col_community_tip: 'The more active users/total users exist, the more active the community will be',
  },
  event: {
    title: "Gamefi's latest activity summary",
    last_week: 'Last Week',
    next_week: 'Next Week',
    today: 'Back to Today',
    day_mon: 'Mon',
    day_tues: 'Tue',
    day_wed: 'Wed',
    day_thur: 'Thur',
    day_fri: 'Fri',
    day_sat: 'Sat',
    day_sun: 'Sun',
    all: 'All',
    white_list: 'White List',
    airdrop: 'Airdrops',
    live: 'Live',
    staking: 'Activity',
    others: 'Others',
    time: 'Time',
    event: 'Event',
    type: "Type",
    start: 'Start',
    end: 'End'
  },
  news: {
    title: 'All News'
  },
  strategy: {
    title: 'All Game Guide'
  },
  about: {
    title: 'My MetaData focuses on analyzing on-chain data, aiming to be the data infrastructure of GameFi & Metaverse',
    desc: 'Now, we have already collected more than 20 types of indexes from different dimensions including on-chain transaction, social platforms, etc. We are committed to gathering all kinds of online data to provide players and investors the most unbiased, accurate and understandable data, helping them select and participate projects much easier',
    services_title: 'Service',
    service_analytics: 'Analytics',
    service_analytics_desc: 'We are focusing on collecting and analyzing on-chain data, through which players, guilds and investment vehicles could have a deeper insight into blockchain applications',
    service_cooperation: 'Cooperation',
    service_cooperation_desc: 'Wallets, exchanges, media and KOLs are all our long-term partners and friends. We would like to share our resources with all developers and teams of blockchain applications, helping them make better marketing and promotion plans',
    service_advertising: 'Advertising',
    service_advertising_desc: 'Our products are accessed by a large number of users every day. Using our advertising services can increase exposure and add weight to your blockchain applications',
    contact_us_title: 'Contact us'
  },
  tokens: {
    title: 'Game Token',
    sub_title: 'List of game items arranged by the circulation market value of game token',
    col_name: 'Name',
    col_price: 'Price',
    col_chg: 'Applies',
    col_market_cap: 'Market Cap',
    col_price_7d: 'Price(7D)',
    price_tip: 'Current prices are updated every five minutes',

  },
  rank: {
    title: 'Game Rankings',
    sub_title1: 'Track data from',
    sub_title2: 'GameFi projects、',
    sub_title3: 'game Tokens,',
    sub_title4: 'game guilds and ',
    sub_title5: 'VCs',
  },
  social: {
    twitter_hot: 'Twitter Hot',
    rank_title: 'Trending Games',
    rank_sub_title: 'Ranked by the number of items mentioned in Twitter KOL tweets',
    check_title: 'Check % of KOL for any GameFi Project',
    check_sub_title: 'Ranked by how many Kols follow each Games Twitter account',
    kol_title: 'List of newly followed accounts on Twitter KOL',
    kol_sub_title: 'Ranked by the number of items mentioned in Twitter KOL tweets',
    col_name: 'KOL ID',
    col_game: 'Game',
    col_volume: 'Social Volume',
    col_volume_7d: 'Social Volume 7D',
    col_kol: 'Mentions KOL',
    col_follower: 'KOL Followers',
    col_follower1: 'Followers',
    col_account: 'KOL Account',
    col_engagement: 'Social Engagement',
    col_tw: 'Twitter ID',
    col_tweet: 'Tweets',
    col_like: 'Likes',
    col_reply: 'Replies',
    col_retweet: 'Retweets',
    col_quote: 'Quotes',
    volume: 'Social Volume',
    engagement: 'Social Engagement',
    kol: 'Mentions KOL',
    volume_tip: 'The number of times KOL’s tweets mentioned the project',
    engagement_tip: 'The number of tweets mentioning the project',
    kol_tip: 'Mention the number of Kols for the project'

  },
  kol: {
    col_name: 'Game'
  },
  overview: {
    nft_yes: 'YES',
    nft_no: 'NO',
    f2p_yes: 'YES',
    f2p_no: 'NO',
    need_nft: 'NFT Required',
    platform: 'Platform',
  },
  games: {
    ad: 'Ad',
    title: 'Game Rankings',
    sub_title: 'List of game items arranged according to transaction data on the chain and other indicators',
    project_title: 'About',
    transaction_title: 'Onchain Data',
    social_title: 'Community',
    event_title: 'Event Calendar',
    strategy_title: 'Trends',
    volume_title: 'Social',
    strategy: 'Strategy',
    v: 'KOL’s View',
    overview: 'Overview',

    developer: 'Developer',
    whale: 'Whales',

    //project
    team: 'Team',
    investors: 'Investors',
    model: 'Economic Model',
    play: 'How to play',

    //transfer
    active_user: 'Active Users',
    active_user_tip: "Number of unique wallet addresses interacting with Dapp's smart contracts",
    volume: 'Volume',
    volume_tip: "Total amount of incoming value to Dapp's smart contracts",
    transactions: 'Transactions',
    transactions_tip: "Transactions made to Dapp's smart contracts",
    balance: 'Balance',
    currentmarket: 'Market Cap',
    balance_tip: "The total market value of a cryptocurrency's circulating supply",
    price: 'Price',


    twitter_hot: 'Twitter Hot',
    kol_follower: 'KOL Following',
    recommend: 'Related to recommend',

    //自定义
    customize: 'Customize',
    custom_title: 'You can add, remove, and/or sort metrics below based on your needs.',
    custom_type: 'Custom',
    custom_default: 'Default',
    custom_reset: 'Reset',
    custom_ok: 'Save',
    custom_colse: 'Cancel',
    custom_placeholder: '* Please select at least one metric.',


    //socail
    followers: '点赞数',
    posts: '发帖数',
    replies: '回复数',
    retweets: '转发数',
    likes: '引用数',
    //token
    token_data: 'Token Data',
    token_holder: 'Token Holders',
    token_holder_rate: 'Token Holders Growth Rate',
    token_symbol: 'Token',
    token_change_rate_24h: 'Token Growth Rate',
    token_price: 'Token Price',


    //table
    col_name: 'Name',
    col_chain: 'Chain',
    col_tag: 'Tag',
    col_new_users: 'New Users',
    col_total_users_v0: 'Total Users',
    col_active_user: 'Active User',
    // col_old_active_user: 'Old Active User',
    col_active_user_change: 'Active User Change',
    col_active_user_7d: 'Active User(7D)',
    col_volume: 'Volume',
    col_volume_change: 'Volume Change',
    col_volume_7d: 'Volume(7D)',
    col_community: 'Community',
    col_total_address: 'Total Addresses',
    col_total_address_h5: 'Addresses',
    col_transfers: 'Transfers',
    col_tag: 'Tags',
    col_active_user_tip: "Number of unique wallet addresses interacting with Dapp's smart contracts",
    col_community_tip: 'The weighted value is calculated based on the amount of users on Twitter、 Telegram and Discord',
    // col_total_address_tip: 'All independent addresses that interact with smart contracts.',
    col_total_address_tip: 'The more addresses are registered, the more holders will have',
    col_vol_add: "Address",
    col_count: "Volume",
    col_value: "Value",
    col_time: 'Time',
    vol_rank: 'Top 100 Volume Rankings',
    trans_vol: 'Distribution of trading Volume',
    trans_vol_trend: 'Distribution trend of trading volume',
    trans_count: 'Distribution of transaction times',
    trans_count_trend: 'Distribution trend of transaction times',
    trans_vol_tooltip1: 'The number of addresses <br/> that  accumulated ',
    trans_vol_tooltip2: ' USD <br/>transactions',
    trans_count_tooltip1: 'Number of addresses<br/> traded ',
    trans_count_tooltip2: ' times',

    game_introduction: 'Game introduction',
    game_detailed: 'Detailed information',
    game_chain: 'Blockchain',
    game_list: 'Related games'

  },
  investor: {
    title: 'VC',
    sub_title: 'Track and count gamefi projects invested by 300 + institutions',
    col_name: 'Investor',
    col_investment_count: 'Total invested games',
    col_market_cap: 'Market Cap',
    col_project_name: 'Recent Investment',
    project: 'Investment Projects',
    list_title: 'Others',
    date: 'Date',
    rounds: 'Rounds',
    money: 'Money',
    company: 'Investor',
    info: 'Info'

  },
  studio: {
    title: 'Game Studio',
    tabble_title: 'Game Developer',
    sub_title: 'Tracking and counting GameFi projects developed by 80+ game studios',
    project: 'The game developed',
    list_title: 'Others',
    date: 'Date',
    rounds: 'Rounds',
    money: 'Money',
    company: 'Investor',
    info: 'Info',
    col_name: 'Name',
    col_area: 'Region',
    col_money: 'Money',
    col_game: 'The game developed',
  },
  guild: {
    title: 'Game Guild',
    sub_title: 'Name list of blockchain game guilds collected by My MetaData',
    col_name: 'Name',
    col_community: 'Community',
    col_price: 'Price',
    col_market_cap: 'Circulating Market Cap',
    col_country: 'Country',
    list_title: 'Others',
    market: 'Markets',
  },
  view: {
    followers: 'Followers',
    like: 'Likes',
    reply: 'Replies',
    default: 'Default',
    empty: 'OVER'
  },
  trends: {
    game: 'Game Trends',
    other: 'Others',

  },
  subject: {
    data: 'Onchain Data',
    social: 'Community',
    v: 'Viewpoint',
    trends: 'Trends',
    expand: 'Expand',
    collapse: 'Collapse',
    activeusers: 'Active Users',
    totalusers: 'Total Users',
    newusers: 'New Users',
    overview: 'Overview',



    new_deposited_users: 'New Deposited Users',
    daily_deposited_users: 'Daily Deposited Users',
    new_withdraw_users: 'New Withdraw Users',
    daily_withdraw_users: 'Daily Withdraw Users',
    deposited_sol: 'Deposited Sol',
    profit: 'Profit',
    deposited: 'Day in',
    withdraw: 'Day out',
    net_deposited: 'Net',
    holders: 'Holders',
    social_volume: 'Volume',
    social_engagement: 'Engagement',
    mentions_kol: 'Mentions KOL',
    twitter_followers: 'Twitter Followers',
    discord_members: 'Discord Members',
    telegram_members: 'Telegram Members',
    socialUsers: 'Social Users',
    depositedusers: 'Deposited Users',
    withdrawusers: 'Withdraw Users',
    depositeamounts: 'Deposited Amounts',
    withdrawamounts: 'Withdraw Amounts',
    twitterHot: 'Twitter Hot',
    socialHot: 'Community',
  },
  err: {
    back: 'Back',
    nutfound: 'Not Found.',
  },
  data: {
    title: 'GameFi Market Data',
    sub_title: 'The latest summary of all on-chain Gamefi data',
    all_game: 'Trend of Total Games',
    game_chain_rate: 'Proportion of Games by Chain',
    game_chain: 'Trend of Games by Chain',
    gamer: 'Trend of Total Gamers',
    gamer_chain_rate: 'Proportion of Gamers by Chain',
    gamer_chain: 'Trend of Gamers by Chain',


    volume: 'Trend of Total Volume',
    volume_chain: 'Trend of Volume by Chain',
    volume_chain_rate: 'Proportion of Volume by Chain',


    transactions: 'Trend of Total Transactions',
    transactions_chain: 'Trend of Transactions by Chain',
    transactions_chain_rate: 'Proportion of Transactions by Chain',

  },
  kline: {
    total: 'Games(24H)',
    active: 'Users(24H)',
    volume: 'Volume(24H)',
    transactions: 'Transactions(24H)',
    total_desc: 'Total amount of games from all chains being tracked by My MetaData',
    active_desc: 'Number of wallet addresses interacted at least once with all Dapps’ smart contracts being tracked by My MetaData in the past 24 hours',
    volume_desc: 'Total volume (in dollar terms) of all games  being tracked by My MetaData in the past 24 hours',
    trans_desc: 'Number of transactions of all games being tracked by My MetaData in the past 24 hours.'
  },
  GameFiTabs: {
    tab1: 'Market Data',
    tab2: 'Game Rankings',
    tab3: 'Game Token',
    tab4: 'Game Guild',
    tab5: 'VC',
    tab6: 'Game Studio',
    tab7: 'New Games',

  },
  banner: {
    title1: 'The Data-driven ',
    title2: ' Analytics Platform',
    sub_title: 'Discover potential opportunities by analyzing on-chain data, financial events, whales’ and Twitter KOLs’ behavior.',
    start: 'Start',
    item1: 'GameFi Projects',
    item2: 'Whales',
    item3: 'Twitter Influencers',
    item4: 'Guilds/VC',
  },
  footer: {
    rank: 'Game Rankings',
    hot: 'Community Data',
    airdrop: 'Airdrops',
    product: 'Products',
    business: 'Company',
    social: 'Community',
    about: 'About Us',
    contact: 'Contact Us',
    included: 'Submit A Game',
  },
  submit: {
    success: 'Success',
    concat: 'We will contact you in 1-3 working days',
    sure: 'Sure',
    apply: 'Included to apply for',
    ok: 'Submit',
    contract: 'Please enter the complete game contract',
    name: 'Game Name',
    desc: 'Game Introduction',
    website: 'Website Links',
    twitter: 'Twitter Links',
    telegram: 'Telegram Links',
    discord: 'Discord Links',
    contact: 'Your contact information: Telegram account (we can get in touch with you)',
    contracts: 'Game Contract',

  },
  // notfound: {
  //   p1: 'According to local government regulatory policies',
  //   p2: 'My MetaData is temporarily unavailable in mainland Chinese',
  //   p3: 'Overseas game promotion/business cooperation, please contact for any other questions',
  // }
  notfound: {
    p1: '根据当地政府监管政策',
    p2: 'My MetaData暂不能对中国大陆地区提供服务',
    p3: '海外游戏推广/商务合作，其他任何问题请联系',
  }
}