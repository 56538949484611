import axios from 'axios'
import code from './code'
axios.defaults.timeout = 30000
// axios.defaults.headers.common["Authorization"] = 'Bearer ' + utils.localDB.get("clientToken")
// axios.defaults.headers.common['x-forwarded-for'] = '127.0.0.1'

const toType = (obj) => { return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase(); }

const filterNull = (o) => { if (toType(o) !== 'object' || o.nofilter) return o; for (var key in o) { if (o[key] === null) { delete o[key] }; if (toType(o[key]) === 'string') { o[key] = o[key].trim(); if (o[key].length === 0) { delete o[key] } }; }; return o; }

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const { data } = response
    return response;
}, function (error) {
    if (error === undefined || error.code === 'ECONNABORTED') {
        // kui.Message.error('网络超时，请稍候重试')
        return Promise.reject(error)
    }
    // const { response: { status, statusText, data: { msg = '服务器发生错误' } } } = error
    // const { response } = error

    return Promise.reject(error);
});

let http = {
}
http.base = (...args) => {
    //noEncrypt 参数不加密 //noProxy 域名要不要代理
    let url = args[1], { noEncrypt,noProxy } = args[2];
    // pro
    let baseUrl = noEncrypt ? 'api.mymetadata.io' : (process.server ? 'www.mymetadata.io' : (location.hostname.indexOf('www.') >= 0 || location.hostname.indexOf('localhost') >= 0 ? 'www.mymetadata.io' : 'mymetadata.io'))
    //test
    // let baseUrl = noEncrypt ? 'api.mymetadata.io' : 'test.mymetadata.io'
    let config = {
        url: noProxy ? url : 'https://' + baseUrl + url,//'https://www.mymetadata.io' + url,
        method: args[0]
    };
    // console.log(config.url)

    let callback = args[2]
    let err = args[3]
    let headers = {}
    let dataType = toType(args[2]);
    if (dataType == 'object' || dataType == 'formdata') {
        let params = null
        callback = args[3] || function () { }
        err = args[4]

        params = filterNull(args[2]) || {}
        // console.log(params);
        if (noEncrypt) {
            if (args[0] == 'post') {
                config.data = params
            } else {
                config.params = params
            }
        } else {
            params = code.enCodeResult(params)
            if (args[0] == 'post') {
                let form = new FormData()
                form.append('variables', params)
                config.data = form
            } else {
                config.params = { variables: params }
            }
        }

    }
    config.headers = headers

    return axios(config).then(res => {
        // kui.Loading.finish();
        let { data } = res
        // console.log(res)
        //如果是登录请求，并且登录成功，就改变状态
        if (res.status == 200) {
            //不解密返回的没有code message
            if (noEncrypt) {
                callback(data)
            } else {
                if (data.code !== 0) {
                    if (err) {
                        return err(data)
                    }
                } else {
                    callback(data)
                }
            }
        }
        // // return new Promise((r,j)=>r(res))
        return data
    }).catch((e) => {
        // kui.Loading.finish();
        console.log('error:', e)
    })
}
['get', 'post', 'put', 'delete'].forEach(h => http[h] = (...args) => http.base(h, ...args))

http.install = (Vue) => { Vue.prototype.$http = http };
export default http;