let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
export default {
  enCodeResult(params) {
    let password1 = 'yfi6n9', password2 = 'c39lzpk'
    return this.encode(password1 + this.encode(JSON.stringify(params)) + password2)
  },
  encode(e) {
    var t, n, r, o, i, a, s, u = "", c = 0;
    for (e = this._utf8_encode(e); c < e.length;)
      o = (t = e.charCodeAt(c++)) >> 2,
        i = (3 & t) << 4 | (n = e.charCodeAt(c++)) >> 4,
        a = (15 & n) << 2 | (r = e.charCodeAt(c++)) >> 6,
        s = 63 & r,
        isNaN(n) ? a = s = 64 : isNaN(r) && (s = 64),
        u = u + _keyStr.charAt(o) + _keyStr.charAt(i) + _keyStr.charAt(a) + _keyStr.charAt(s);
    return u
  },
  decode(e) {
    var t, n, r, o, i, a, s = "", u = 0;
    for (e = e.replace(/[^A-Za-z0-9\+\/\=]/g, ""); u < e.length;)
      t = _keyStr.indexOf(e.charAt(u++)) << 2 | (o = _keyStr.indexOf(e.charAt(u++))) >> 4,
        n = (15 & o) << 4 | (i = _keyStr.indexOf(e.charAt(u++))) >> 2,
        r = (3 & i) << 6 | (a = _keyStr.indexOf(e.charAt(u++))),
        s += String.fromCharCode(t),
        64 !== i && (s += String.fromCharCode(n)),
        64 !== a && (s += String.fromCharCode(r));
    return s = this._utf8_decode(s)
  },
  _utf8_encode(e) {
    e = e.replace(/\r\n/g, "\n");
    for (var t = "", n = 0; n < e.length; n++) {
      var r = e.charCodeAt(n);
      r < 128 ? t += String.fromCharCode(r) : r > 127 && r < 2048 ? (t += String.fromCharCode(r >> 6 | 192),
        t += String.fromCharCode(63 & r | 128)) : (t += String.fromCharCode(r >> 12 | 224),
          t += String.fromCharCode(r >> 6 & 63 | 128),
          t += String.fromCharCode(63 & r | 128))
    }
    return t
  },
  _utf8_decode(e) {
    for (var t = "", n = 0, r = 0, o = 0, i = 0; n < e.length;)
      (r = e.charCodeAt(n)) < 128 ? (t += String.fromCharCode(r),
        n++) : r > 191 && r < 224 ? (o = e.charCodeAt(n + 1),
          t += String.fromCharCode((31 & r) << 6 | 63 & o),
          n += 2) : (o = e.charCodeAt(n + 1),
            i = e.charCodeAt(n + 2),
            t += String.fromCharCode((15 & r) << 12 | (63 & o) << 6 | 63 & i),
            n += 3);
    return t
  },
}