import Vue from "vue";
import VueI18n from 'vue-i18n'
import KUI from 'kui-vue';
// Vue.use(kui);

Vue.use(VueI18n)
import zh from './zh'
import en from './en'
import kui_en from 'kui-vue/components/locale/lang/en'
import kui_zh from 'kui-vue/components/locale/lang/zh-CN'
// import locale from 'kui-vue/components/locale'
// const a = require('kui-vue/components/locale').i18n
export default ({ app, store }) => {
  let i18n = new VueI18n({
    locale: store.state.lang,
    fallbackLocale: 'en',
    messages: {
      zh: Object.assign(zh, kui_zh),
      en: Object.assign(en, kui_en),
    }
  });
  app.i18n = i18n
  // locale.use('en')
  // app.i18n.path = (link) => {
  //   // 如果是默认语言，就省略
  //   if (app.i18n.locale === app.i18n.fallbackLocale) {
  //     return `/${link}`
  //   }
  //   return `/${app.i18n.locale}/${link}`
  // }
  Vue.use(KUI, {
    i18n: (key, value) => i18n.t(key, value)
  })
  // locale.i18n((key, value) => app.i18n.t(key, value))
}
