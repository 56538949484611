module.exports = {
  share: '分享',
  copy_success: '复制成功',
  share_tiwtter: '分享到Twitter',
  copy_link: '复制链接',
  share_poster: '分享海报',
  com: {
    view_more: '查看更多',
    empty: '暂无数据',
    view_all: '查看全部',
    hour: '时',
    day: '天',
    over: '后面没有了',
    more: '加载更多',
    pagesize: '显示行',
    all: '全部',
    alltabs: '全部链',
    alltags: '全部标签',
  },

  menu: {
    trend: '热门游戏',
    home: '首页',
    rank: '游戏排行',
    event_calendar: '日历',
    news: '资讯',
    strategy: '攻略',
    research: '研究',
    about: '关于',
    lang: '夜间模式',
    search: '搜索',
    whale: '巨鲸',
    gamefi: 'GameFi报告',

  },
  home: {
    kline_title: 'GameFi市场数据',
    kline_sub_title: '各公链GameFi应用最新数据汇总',
    news_title: '行业要闻',
    strategy_title: '游戏攻略',
    rank_active_user: '活跃用户增长榜',
    rank_holder_token: '持币地址增长榜',
    rank_increase_list: '涨幅榜',
    rank_decline_list: '跌幅榜',
    rank_latest_collection: '最新收录',

    col_name: '名称',
    col_chain: '公链',
    col_socail: '社交',
    col_active_24: '活跃用户 24H',
    col_holders_24: '持币地址 24H',
    col_volume_7d: '交易量(7D)',
    col_active_user_7d: '活跃用户(7D)',
    col_latest: '收录时间',
    col_price_24: '价格($) 24H',
    col_total: '流通市值',
  },
  ido: {
    hot: '社群规模',
    price: '价格',
    total: '总量',
    start_time: '开始时间',
    end_time: '结束时间',
    preview: '即将开始',
    ongoing: '进行中',
    finished: '已结束',
    price: '价格',
    join: '参与',
    whitelist: '白名单',
    tba: '待宣布',
  },
  newgame: {
    testing: '正在内测',
    financing: '最近融资',
    all: '最近上新',
    coming: '即将上线',
    recommend: '推荐',
    title: '新游戏',
    sub_title: '平台编辑精选的最新的GameFi项目'
  },
  trend: {
    title: '热门游戏',
    sub_title1: '通过追踪 ',
    sub_title2: 'Twitter KOL推文、',
    sub_title3: '公会/机构动态发现热门的GameFi项目',
    follower: 'Twitter KOL关注榜',
    kol_follower: 'KOL关注',
    hot: 'Twitter KOL热议榜',
    investor: '机构最新投资',
    table_follower: 'KOL关注',

    table_hot: 'Twitter热度',
    kol_title: 'Twitter KOL 关注榜',
    kol_sub_title: '可以查看每个游戏项目的Twitter KOL关注情况。',
    hot_title: 'Twitter KOL 热议',
    hot_sub_title: '统计分析Twitter KOL的推文，按照提到游戏项目的推文数量排列的游戏列表',
    investor_title: '机构最新投资',
    investor_sub_title1: '跟踪',
    investor_sub_title2: '机构的投资动态，发现早期的GameFi项目',

    investor_time: '时间',
    investor_game: '游戏名称',
    investor_investor: '机构名称',
    investor_round: '轮次',
    investor_raised: '金额',
    investor_link: '链接',
  },
  alpha: {
    alpha_active_user: '活跃用户飙升(24H)',
    alpha_holder_token: '持币地址飙升(24H)',
    alpha_new_active_user: '新增活跃用户(24H)',
    alpha_new_holder_token: '新增持币地址(24H)',
    alpha_increase: '涨幅榜',
    alpha_decline: '跌幅榜',
    title: '发现alpha游戏',
    sub_title1: '通过追踪',
    sub_title2: '个游戏链上数据，发现Alpha GameFi项目',
    sub_title3: '个游戏Token价格，发现Alpha GameFi项目',

  },
  airdrop: {
    title: '最新空投',
    sub_title1: '聚合',
    sub_title2: '家IDO平台、追踪',
    sub_title3: '个游戏官方Twitter，捕捉GameFi最新参与机会',
  },
  whaleTabs: {
    tab1: '巨鲸变动榜',
    tab2: '巨鲸交易',
  },
  whale: {
    time: '最近更新',
    title: '巨鲸动向',
    sub_title1: '追踪 ',
    sub_title2: ' 个项目的 ',
    sub_title3: ' 个巨鲸地址，观察项目的动向',
    whale_growth: '巨鲸增加量飙升(24H)',
    whale_increase: '巨鲸增持榜(24H)',
    whale_decline: '巨鲸减持榜(24H)',
    col_name: '游戏名称',
    col_growth_new: '新增巨鲸',
    col_growth_change: '巨鲸持仓变化',
    col_increase_addcount: '增持巨鲸数',
    col_increase_addprecent: '增持比例',
    col_decline_minuscount: '减持巨鲸数',
    col_decline_minusprecent: '减持比例',
    tips: '巨鲸定义: 持有本项目的Token数量占总供应量的比例大于等于0.1%',
    card_address: '巨鲸地址数量',
    card_address_precent: '占总持币地址比例',
    card_token: '巨鲸持有token数量',
    card_token_precent: '占总供应量比例',
    list_title: '巨鲸列表',
    pie_type: '巨鲸类型分布',
    pie_assets: '占总资产的比例分布',
    pie_token: '巨鲸持有的其他Token',
    col_whale_add: '地址',
    col_assets: '持有量',
    col_precent: '占比',
    col_type: '类型',
    col_change: '变动',
    pie_assets_title1: '占总资产比例',
    pie_assets_title2: '的巨鲸数',
    pie_token_title1: '持有',
    pie_token_title2: '的巨鲸数',
  },
  nft: {
    sub_title: '根据Discord的活跃用户来对即将上线的NFT项目进行排名',
    col_name: '名称',
    col_user: '用户数',
    col_active_user: '活跃用户',
    col_community: '社区活跃度',
    col_start_time: '开始时间',
    col_mint_price: '薄荷价',
    col_links: '社交',
    new: '最新的',
    col_community_tip: '活跃用户/总用户数，数值越大，社群越活跃',

  },
  event: {
    title: "Gamefi最新活动汇总",
    last_week: '上周',
    next_week: '下周',
    today: '返回今日',
    day_mon: '周一',
    day_tues: '周二',
    day_wed: '周三',
    day_thur: '周四',
    day_fri: '周五',
    day_sat: '周六',
    day_sun: '周日',
    all: '全部',
    white_list: '白名单',
    airdrop: '空投',
    live: '直播',
    staking: '活动',
    others: '其它',
    time: '时间',
    event: '事件',
    type: "类型",
    start: '开始',
    end: '结束'
  },
  news: {
    title: '资讯'
  },
  strategy: {
    title: '游戏教程'
  },
  about: {
    title: 'My Meta Data 专注于链上数据分析, 目标是成为GameFi/ MetaVerse的数据基础设施',
    desc: '目前已经收集了链上交易、社交平台等维度的20+数据指标。我们致力于采集项目的各类线上数据，希望通过客观、准确且易懂的数据向用户描述和传递项目情况，帮助玩家/投资者更好的参与项目',
    services_title: '我们的服务',
    service_analytics: '数据分析',
    service_analytics_desc: '我们专注于提供区块链应用的链上数据和分析，玩家、公会、投资机构等使用我们的数据产生对区块链应用有更深层的理解和洞察。',
    service_cooperation: '资源对接',
    service_cooperation_desc: '钱包，交易所，媒体，KOL 都是我们长期的合作伙伴和好朋友。我们愿意把这些资源分享给所有区块链应用的开发者和团队，来更好地帮助项目营销。',
    service_advertising: '广告投放',
    service_advertising_desc: '我们的产品每天有大量的用户访问，使用我们的广告服务，可以为区块链应用带来一定的曝光量和影响力。',
    contact_us_title: '与我们联系'
  },
  rank: {
    title: '游戏排行',
    sub_title1: '追踪',
    sub_title2: '个游戏项目、',
    sub_title3: '个游戏Token，',
    sub_title4: '家游戏公会、',
    sub_title5: '家投资机构数据',
  },
  social: {
    twitter_hot: 'Twitter热度',
    rank_title: '热门游戏',
    rank_sub_title: '根据Twitter KOL推文提到的项目次数排名',
    check_title: 'Check % of KOL for any GameFi Project',
    check_sub_title: '根据每个Games的Twitter账号有多少KOL关注排名',
    kol_title: 'Twitter KOL新增关注账号榜单',
    kol_sub_title: '根据Twitter KOL推文提到的项目次数排名',
    col_name: 'KOL账号',
    col_game: '游戏名称',
    col_volume: '推文数量',
    col_volume_7d: '推文数量(7D)',
    col_kol: 'Mentions KOL',
    col_follower: 'KOL粉丝数',
    col_follower1: '粉丝数',
    col_account: 'KOL账号',
    col_engagement: '推文热度',
    col_tw: '推特账号',
    col_tweet: '推文数量',
    col_like: '点赞',
    col_reply: '回复',
    col_retweet: '转推',
    col_quote: '引用',
    volume: '推文数量',
    engagement: '推文热度',
    kol: 'Mentions KOL',
    volume_tip: 'KOL的推文提到项目的次数',
    engagement_tip: '提到项目的推文曝光量',
    kol_tip: '推文中提到该项目的KOL名单'
  },
  view: {
    followers: '关注者',
    like: '点赞数',
    reply: '评论数',
    default: '默认排序',
    empty: '后面没有了'


  },
  kol: {
    col_name: '项目名称'
  },
  trends: {
    game: '游戏动态',
    other: '其它',
  },
  subject: {
    data: '链上数据',
    social: '社区热度',
    v: 'KOL观点',
    trends: '项目动态',
    expand: '展开',
    collapse: '收起',
    overview: '概览',


    activeusers: '活跃用户',
    totalusers: '累计用户',
    newusers: '新增用户',


    new_deposited_users: '首次充值用户',
    daily_deposited_users: '非首次充值用户',
    new_withdraw_users: '首次提现用户',
    daily_withdraw_users: '非首次提现用户',
    deposited_sol: '充值金额',
    profit: '利润估算',
    deposited: '日流入',
    withdraw: '日流出',
    net_deposited: '日净流入',
    holders: '持币地址',

    social_volume: '推文数量',
    social_engagement: '推文热度',
    mentions_kol: 'Mentions KOL',
    twitter_followers: 'Twitter用户',
    discord_members: 'Discord用户',
    telegram_members: 'Telegram用户',
    socialUsers: '项目方社区用户',
    twitterHot: 'Twitter热度',
    socialHot: '社区活跃',
    depositedusers: '充值用户',
    withdrawusers: '提现用户',
    depositeamounts: '充值金额',
    withdrawamounts: '提现金额',
  },
  tokens: {
    title: '游戏Token',
    sub_title: '以游戏Token的流通市值排列的游戏项目列表',
    col_name: '名称',
    col_price: '价格',
    col_chg: '涨跌幅',
    col_market_cap: '流通市值',
    col_price_7d: '价格走势(7D)',
    price_tip: '当前价格5分钟更新一次',
  },
  overview: {
    nft_yes: '需要',
    nft_no: '不需要',
    f2p_yes: '是',
    f2p_no: '否',
    need_nft: '需要NFT',
    platform: '支持设备',
  },
  games: {
    ad: '广告',
    title: '游戏排行',
    sub_title: '按照链上交易数据和其他指标排列的游戏项目列表',
    project_title: '简介',
    transaction_title: '链上数据',
    social_title: '社区热度',
    event_title: '日历',
    strategy_title: '动态',
    volume_title: 'Social',
    strategy: '攻略',
    overview: '概览',



    v: 'KOL观点',
    developer: '开发者',
    whale: '巨鲸分析',



    //project
    team: '团队',
    investors: '投资机构',
    model: '经济模型',
    play: '玩法',

    //transfer
    active_user: '活跃用户',
    active_user_tip: '与dapp的智能合约交互的唯一钱包地址数量',
    volume: '交易量',
    volume_tip: 'dapp智能合约的传入价值总额',
    transactions: '交易次数',
    transactions_tip: 'dapp智能合约的交易',
    balance: '余额',
    currentmarket: '流通市值',
    balance_tip: '项目Token流通市值的总和',
    price: '价格',

    twitter_hot: 'Twitter热度',
    kol_follower: 'KOL关注数',
    recommend: '相关推荐',



    //socail
    followers: '点赞数',
    posts: '发帖数',
    replies: '评论数',
    retweets: '转发数',
    likes: '引用数',

    //token
    token_data: 'Token数据',
    token_holder: '持币地址数量',
    token_holder_rate: '持币地址增长率',
    token_symbol: 'Token名称',
    token_change_rate_24h: 'Token涨跌幅',
    token_price: 'Token价格',
    //自定义
    customize: '自定义字段',
    custom_title: '您可以根据需要在下面添加、删除或排序指标。',
    custom_type: '自定义',
    custom_default: '默认模式',
    custom_reset: '重置',
    custom_ok: '保存',
    custom_colse: '取消',
    custom_placeholder: '* 请至少选择一项指标。',

    //table
    col_name: '名称',
    col_new_users: '新增用户',
    col_total_users_v0: '累计用户',
    col_chain: '公链',
    col_tag: '标签',
    col_active_user: '活跃用户',
    // col_old_active_user: '活跃老用户',
    col_active_user_7d: '活跃用户(7D)',
    col_active_user_change: '活跃用户变化',
    col_volume: '交易量',
    col_volume_change: '交易量变化',
    col_volume_7d: '交易量(7D)',
    col_community: '社群规模',
    col_total_address: '持有者数量',
    col_total_address_h5: '持有者数量',
    col_transfers: '交易次数',
    col_tag: '标签',
    col_active_user_tip: '与dapp的智能合约交互的唯一钱包地址数量',
    col_community_tip: '根据Twitter、Discord、Telegram的用户加权计算得出',
    // col_total_address_tip: '所有与智能合约有交互的独立地址数',
    col_total_address_tip: '币种持仓地址数，数值越大，持有人数越多',
    col_vol_add: "地址",
    col_vol: "地址",
    col_count: "交易数量",
    col_value: '价值',
    col_time: '交易时间',
    vol_rank: 'Top 100交易量排行',
    trans_vol: '交易量分布',
    trans_vol_trend: '交易量分布趋势',
    trans_count: '交易次数分布',
    trans_count_trend: '交易次数分布趋势',
    trans_vol_tooltip1: '累计交易',
    trans_vol_tooltip2: ' USD的地址数',
    trans_count_tooltip1: '交易',
    trans_count_tooltip2: '次的地址数',

    game_introduction: '游戏简介',
    game_detailed: '详细信息',
    game_chain: '所属链',
    game_list: '相关游戏'

  },
  investor: {
    title: '投资机构',
    sub_title: '跟踪并统计300+机构投资的GameFi项目',
    col_name: '机构名称',
    col_investment_count: '总投资游戏数',
    col_market_cap: '所投项目总市值',
    col_project_name: '最近投资',
    project: '投资项目',
    list_title: '其他机构',
    date: '发布日期',
    rounds: '融资轮次',
    money: '融资金额',
    company: '投资方',
    info: '融资信息'

  },
  studio: {
    title: '游戏工作室',
    tabble_title: '游戏开发者',
    sub_title: '跟踪并统计80+游戏工作室开发的GameFi项目',
    project: '开发的游戏',
    list_title: '其他工作室',
    date: '发布日期',
    rounds: '融资轮次',
    money: '融资金额',
    company: '投资方',
    info: '融资信息',
    col_name: '工作室名称',
    col_area: '地区',
    col_money: '融资金额',
    col_game: '开发的游戏',
  },
  guild: {
    title: '游戏公会',
    sub_title: 'My MetaData整理的GameFi头部游戏公会数据',
    col_name: '名称',
    col_community: '社群规模',
    col_price: '价格',
    col_market_cap: '流通市值',
    col_country: '国家',
    list_title: '其它工会',
    market: '行情信息',
  },
  err: {
    back: '返回',
    nutfound: '您访问的页面被人偷了，您要不试试其他的',

  },

  kline: {
    total: '游戏数量(24H)',
    active: '活跃用户(24H)',
    volume: '交易额(24H)',
    transactions: '交易次数(24H)',
    total_desc: 'MyMetaData获取的各公链GameFi项目之和',
    active_desc: '在过去24小时内跟所有MyMetaData获取的GameFi项目的智能合约有过至少一次交易行为的地址数量',
    volume_desc: '在过去24小时中，所有MyMetaData获取的GameFi项目交易总额（以美元计）',
    trans_desc: '在过去24小时中，所有MyMetaData获取的GameFi项目交易次数之和'
  },
  data: {
    title: 'GameFi 市场总览',
    sub_title: '各个公链GameFi应用最新数据汇总',
    tabname: '市场总览',
    all_game: '游戏数量',
    game_chain_rate: '各公链项目占比',
    game_chain: '各公链项目数',

    gamer: '活跃用户',
    gamer_chain: '各公链活跃用户',
    gamer_chain_rate: '各公链活跃用户占比',

    volume: '交易额',
    volume_chain: '各公链交易额',
    volume_chain_rate: '各公链交易额占比',

    transactions: '交易次数',
    transactions_chain: '公链交易次数',
    transactions_chain_rate: '公链交易次数占比',
  },
  GameFiTabs: {
    tab1: '市场总览',
    tab2: '游戏排行',
    tab3: '游戏Token',
    tab4: '游戏公会',
    tab5: '投资机构',
    tab6: '游戏工作室',
    tab7: '新游戏',
  },
  banner: {
    title1: '数据驱动的',
    title2: '分析平台',
    sub_title: '通过挖掘链上数据、机构投资动态、巨鲸和Twitter KOL的行为来发现GameFi投资机会。',
    start: '开始使用',
    item1: 'GameFi项目',
    item2: '巨鲸地址',
    item3: '推特KOL',
    item4: '公会/机构',

  },
  footer: {
    rank: '游戏排行',
    hot: '社区热度',
    airdrop: '游戏空投',
    product: '产品',
    business: '商务',
    social: '社区',
    about: '关于我们',
    contact: '联系我们',
    included: '收录申请',
  },
  submit: {
    success: '提交成功',
    concat: '我们将在1-3个工作日与您取得联系',
    sure: '确定',
    apply: '收录申请',
    ok: '提交',
    contract: '请输入完整的游戏合约',
    name: '游戏名称',
    desc: '游戏简介',
    website: '官网链接',
    twitter: 'Twitter链接',
    telegram: 'Telegram链接',
    discord: 'Discord链接',
    contact: '您的联系方式：Telegram账号 (我们可以跟您取得联系）',
    contracts: '游戏合约',

  },
  notfound:{
    p1:'根据当地政府监管政策',
    p2:'My MetaData暂不能对中国大陆地区提供服务',
    p3:'海外游戏推广/商务合作，其他任何问题请联系',
  }
}