

export default async function ({ req, app, store, route, res }) {
  let ua = process.server ? req.headers['user-agent'] : navigator.userAgent
  let cookie = process.server ? req.headers.cookie : document.cookie
  //默认
  let lang = app.i18n.fallbackLocale
  let theme = store.state.theme;
  let trendIndex = store.state.trendIndex;
  let rankgameIndex = store.state.rankgameIndex;
  // let ip = '', ips = '{}', real_ip = '';
  //浏览器语言
  if (process.server) {
    lang = (req.headers['accept-language'] || '').indexOf('zh-CN') >= 0 ? 'zh' : 'en'
    // ip = '45.62.169.205' || req.headers["x-real-ip"]
    // real_ip = req.headers["x-real-ip"]
    // ip = Buffer.from(real_ip || '').toString('base64')
    // let allips = (cookie || '').match(new RegExp("(^| )region_reject=([^;]*)(;|$)"))
    // ips = allips ? unescape(allips[2]) : ips
    // let ipsObj = JSON.parse(ips)
    // console.log(real_ip);
  //   if (real_ip) {
  //     if (!ipsObj[ip]) {
  //       let { data } = await fetch('https://opendata.baidu.com/api.php?query=' + real_ip + '&co=&resource_id=6006&oe=utf8').then(res => res.json())
  //       if (data && data.length) {
  //         let location = data[0]['location']
  //         let str = ['联通', '移动', '电信']
  //         console.log(location);
  //         if (str.some((m) => location.indexOf(m) > -1)) {
  //           ipsObj[ip] = 2//跳
  //           store.commit('setNotfound', true)
  //         } else {
  //           store.commit('setNotfound', false)
  //           ipsObj[ip] = 1 //不跳
  //         }
  //         res.setHeader("Set-Cookie", `region_reject=${JSON.stringify(ipsObj)}`);
  //         console.log(JSON.stringify(ipsObj));
  //       }
  //     } else {
  //       console.log('进缓存');
  //       store.commit('setNotfound', ipsObj[ip] == 1 ? false : true)
  //     }
  //   }
  }
  //判断 主题
  let themes = (cookie || '').match(new RegExp("(^| )theme=([^;]*)(;|$)"))
  theme = themes ? unescape(themes[2]) : theme

  //缓存
  let langs = (cookie || '').match(new RegExp("(^| )lang=([^;]*)(;|$)"))
  lang = langs ? unescape(langs[2]) : lang

  let trendIndexs = (cookie || '').match(new RegExp("(^| )trendIndex=([^;]*)(;|$)"))
  trendIndex = trendIndexs ? unescape(trendIndexs[2]) : trendIndex

  let rankgameIndexs = (cookie || '').match(new RegExp("(^| )rankgameIndex=([^;]*)(;|$)"))
  rankgameIndex = rankgameIndexs ? unescape(rankgameIndexs[2]) : rankgameIndex




  if (['zh', 'en'].indexOf(lang) < 0) lang = app.i18n.fallbackLocale
  // let h5 = !!ua.match(/AppleWebKit.*Mobile.*/)
  let h5 = /(Android|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(ua) && !/(?:iPad|PlayBook)/.test(ua)
  store.commit('setPlatform', h5)
  store.commit('setLang', lang)
  store.commit('setTheme', theme)
  store.commit('setTrendIndex', trendIndex)
  store.commit('setRankgameIndex', rankgameIndex)

  let key = route.path.replace(/\/|-/g, '_')
  let keys = [key]
  if (/_rank/.test(key)) {
    keys.push('_rank')
  }
  if (/_trend/.test(key) && !/_trends/.test(key)) {
    keys.push('_trend')
  }
  if (/_whale/.test(key)) {
    keys.push('_whale')
  }
  if ('_news' == key) {
    keys.push('_research')
  }
  // console.log(keys)
  store.commit('setMenuActiveKey', keys)
  app.i18n.locale = lang
}