export const Chain = () => import('../../components/Chain.vue' /* webpackChunkName: "components/chain" */).then(c => wrapFunctional(c.default || c))
export const GameTab = () => import('../../components/GameTab.vue' /* webpackChunkName: "components/game-tab" */).then(c => wrapFunctional(c.default || c))
export const Legend = () => import('../../components/Legend.vue' /* webpackChunkName: "components/legend" */).then(c => wrapFunctional(c.default || c))
export const Num = () => import('../../components/Num.vue' /* webpackChunkName: "components/num" */).then(c => wrapFunctional(c.default || c))
export const Rates = () => import('../../components/Rates.vue' /* webpackChunkName: "components/rates" */).then(c => wrapFunctional(c.default || c))
export const WebIcon = () => import('../../components/WebIcon.vue' /* webpackChunkName: "components/web-icon" */).then(c => wrapFunctional(c.default || c))
export const DateFilter = () => import('../../components/dateFilter.vue' /* webpackChunkName: "components/date-filter" */).then(c => wrapFunctional(c.default || c))
export const Description = () => import('../../components/description.vue' /* webpackChunkName: "components/description" */).then(c => wrapFunctional(c.default || c))
export const Event = () => import('../../components/event.vue' /* webpackChunkName: "components/event" */).then(c => wrapFunctional(c.default || c))
export const CalcWalken = () => import('../../components/calc/walken.vue' /* webpackChunkName: "components/calc-walken" */).then(c => wrapFunctional(c.default || c))
export const KLineHomeitem = () => import('../../components/KLine/homeitem.vue' /* webpackChunkName: "components/k-line-homeitem" */).then(c => wrapFunctional(c.default || c))
export const KLine = () => import('../../components/KLine/index.vue' /* webpackChunkName: "components/k-line" */).then(c => wrapFunctional(c.default || c))
export const KLineItem = () => import('../../components/KLine/item.vue' /* webpackChunkName: "components/k-line-item" */).then(c => wrapFunctional(c.default || c))
export const GamesAbout = () => import('../../components/games/about.vue' /* webpackChunkName: "components/games-about" */).then(c => wrapFunctional(c.default || c))
export const GamesBase = () => import('../../components/games/base.vue' /* webpackChunkName: "components/games-base" */).then(c => wrapFunctional(c.default || c))
export const GamesChainData = () => import('../../components/games/chainData.vue' /* webpackChunkName: "components/games-chain-data" */).then(c => wrapFunctional(c.default || c))
export const GamesCommunity = () => import('../../components/games/community.vue' /* webpackChunkName: "components/games-community" */).then(c => wrapFunctional(c.default || c))
export const GamesDashboard = () => import('../../components/games/dashboard.vue' /* webpackChunkName: "components/games-dashboard" */).then(c => wrapFunctional(c.default || c))
export const GamesEvent = () => import('../../components/games/event.vue' /* webpackChunkName: "components/games-event" */).then(c => wrapFunctional(c.default || c))
export const GamesGuide = () => import('../../components/games/guide.vue' /* webpackChunkName: "components/games-guide" */).then(c => wrapFunctional(c.default || c))
export const GamesIdo = () => import('../../components/games/ido.vue' /* webpackChunkName: "components/games-ido" */).then(c => wrapFunctional(c.default || c))
export const GamesMap = () => import('../../components/games/map.js' /* webpackChunkName: "components/games-map" */).then(c => wrapFunctional(c.default || c))
export const GamesOverview = () => import('../../components/games/overview.vue' /* webpackChunkName: "components/games-overview" */).then(c => wrapFunctional(c.default || c))
export const GamesRoi = () => import('../../components/games/roi.vue' /* webpackChunkName: "components/games-roi" */).then(c => wrapFunctional(c.default || c))
export const GamesScreenshot = () => import('../../components/games/screenshot.vue' /* webpackChunkName: "components/games-screenshot" */).then(c => wrapFunctional(c.default || c))
export const GamesSocial = () => import('../../components/games/social.vue' /* webpackChunkName: "components/games-social" */).then(c => wrapFunctional(c.default || c))
export const GamesStrategy = () => import('../../components/games/strategy.vue' /* webpackChunkName: "components/games-strategy" */).then(c => wrapFunctional(c.default || c))
export const GamesToken = () => import('../../components/games/token.vue' /* webpackChunkName: "components/games-token" */).then(c => wrapFunctional(c.default || c))
export const GamesViewpoint = () => import('../../components/games/viewpoint.vue' /* webpackChunkName: "components/games-viewpoint" */).then(c => wrapFunctional(c.default || c))
export const GamesWhale = () => import('../../components/games/whale.vue' /* webpackChunkName: "components/games-whale" */).then(c => wrapFunctional(c.default || c))
export const KolModal = () => import('../../components/kolModal/index.vue' /* webpackChunkName: "components/kol-modal" */).then(c => wrapFunctional(c.default || c))
export const LangBox = () => import('../../components/langBox/index.vue' /* webpackChunkName: "components/lang-box" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const NftH5 = () => import('../../components/nft/h5.vue' /* webpackChunkName: "components/nft-h5" */).then(c => wrapFunctional(c.default || c))
export const NftPc = () => import('../../components/nft/pc.vue' /* webpackChunkName: "components/nft-pc" */).then(c => wrapFunctional(c.default || c))
export const Social = () => import('../../components/social/index.vue' /* webpackChunkName: "components/social" */).then(c => wrapFunctional(c.default || c))
export const SocialTable = () => import('../../components/social/table.vue' /* webpackChunkName: "components/social-table" */).then(c => wrapFunctional(c.default || c))
export const SocialRankH5 = () => import('../../components/socialRank/h5.vue' /* webpackChunkName: "components/social-rank-h5" */).then(c => wrapFunctional(c.default || c))
export const SocialRank = () => import('../../components/socialRank/index.vue' /* webpackChunkName: "components/social-rank" */).then(c => wrapFunctional(c.default || c))
export const SocialRankPc = () => import('../../components/socialRank/pc.vue' /* webpackChunkName: "components/social-rank-pc" */).then(c => wrapFunctional(c.default || c))
export const Socialkol = () => import('../../components/socialkol/index.vue' /* webpackChunkName: "components/socialkol" */).then(c => wrapFunctional(c.default || c))
export const SocialkolTable = () => import('../../components/socialkol/table.vue' /* webpackChunkName: "components/socialkol-table" */).then(c => wrapFunctional(c.default || c))
export const SubjectsCard = () => import('../../components/subjects/card.vue' /* webpackChunkName: "components/subjects-card" */).then(c => wrapFunctional(c.default || c))
export const SubjectsMelody = () => import('../../components/subjects/melody.vue' /* webpackChunkName: "components/subjects-melody" */).then(c => wrapFunctional(c.default || c))
export const SubjectsRead2n = () => import('../../components/subjects/read2n.vue' /* webpackChunkName: "components/subjects-read2n" */).then(c => wrapFunctional(c.default || c))
export const SubjectsSocial = () => import('../../components/subjects/social.vue' /* webpackChunkName: "components/subjects-social" */).then(c => wrapFunctional(c.default || c))
export const SubjectsStepn = () => import('../../components/subjects/stepn.vue' /* webpackChunkName: "components/subjects-stepn" */).then(c => wrapFunctional(c.default || c))
export const SubjectsTrends = () => import('../../components/subjects/trends.vue' /* webpackChunkName: "components/subjects-trends" */).then(c => wrapFunctional(c.default || c))
export const SubjectsView = () => import('../../components/subjects/view.vue' /* webpackChunkName: "components/subjects-view" */).then(c => wrapFunctional(c.default || c))
export const SubjectsViewitem = () => import('../../components/subjects/viewitem.vue' /* webpackChunkName: "components/subjects-viewitem" */).then(c => wrapFunctional(c.default || c))
export const SubjectsWalken = () => import('../../components/subjects/walken.vue' /* webpackChunkName: "components/subjects-walken" */).then(c => wrapFunctional(c.default || c))
export const Tags = () => import('../../components/tags/index.vue' /* webpackChunkName: "components/tags" */).then(c => wrapFunctional(c.default || c))
export const TagsTooltip = () => import('../../components/tags/tooltip.vue' /* webpackChunkName: "components/tags-tooltip" */).then(c => wrapFunctional(c.default || c))
export const Title = () => import('../../components/title/index.vue' /* webpackChunkName: "components/title" */).then(c => wrapFunctional(c.default || c))
export const TrendsAirdrop = () => import('../../components/trends/airdrop.vue' /* webpackChunkName: "components/trends-airdrop" */).then(c => wrapFunctional(c.default || c))
export const TrendsGame = () => import('../../components/trends/game.vue' /* webpackChunkName: "components/trends-game" */).then(c => wrapFunctional(c.default || c))
export const TrendsGiveaway = () => import('../../components/trends/giveaway.vue' /* webpackChunkName: "components/trends-giveaway" */).then(c => wrapFunctional(c.default || c))
export const TrendsNft = () => import('../../components/trends/nft.vue' /* webpackChunkName: "components/trends-nft" */).then(c => wrapFunctional(c.default || c))
export const TrendsOther = () => import('../../components/trends/other.vue' /* webpackChunkName: "components/trends-other" */).then(c => wrapFunctional(c.default || c))
export const TrendsWhitelist = () => import('../../components/trends/whitelist.vue' /* webpackChunkName: "components/trends-whitelist" */).then(c => wrapFunctional(c.default || c))
export const HomeAirdrop = () => import('../../components/home/airdrop/index.vue' /* webpackChunkName: "components/home-airdrop" */).then(c => wrapFunctional(c.default || c))
export const HomeAlpha = () => import('../../components/home/alpha/index.vue' /* webpackChunkName: "components/home-alpha" */).then(c => wrapFunctional(c.default || c))
export const HomeAlphaTable = () => import('../../components/home/alpha/table.vue' /* webpackChunkName: "components/home-alpha-table" */).then(c => wrapFunctional(c.default || c))
export const HomeGamesChain = () => import('../../components/home/games/chain.vue' /* webpackChunkName: "components/home-games-chain" */).then(c => wrapFunctional(c.default || c))
export const HomeGamesCustomize = () => import('../../components/home/games/customize.vue' /* webpackChunkName: "components/home-games-customize" */).then(c => wrapFunctional(c.default || c))
export const HomeGames = () => import('../../components/home/games/index.vue' /* webpackChunkName: "components/home-games" */).then(c => wrapFunctional(c.default || c))
export const HomeGamesPart = () => import('../../components/home/games/part.vue' /* webpackChunkName: "components/home-games-part" */).then(c => wrapFunctional(c.default || c))
export const HomeGamesPc = () => import('../../components/home/games/pc.vue' /* webpackChunkName: "components/home-games-pc" */).then(c => wrapFunctional(c.default || c))
export const HomeGamesTag = () => import('../../components/home/games/tag.vue' /* webpackChunkName: "components/home-games-tag" */).then(c => wrapFunctional(c.default || c))
export const HomeGamesTags = () => import('../../components/home/games/tags.vue' /* webpackChunkName: "components/home-games-tags" */).then(c => wrapFunctional(c.default || c))
export const HomeGuildH5 = () => import('../../components/home/guild/h5.vue' /* webpackChunkName: "components/home-guild-h5" */).then(c => wrapFunctional(c.default || c))
export const HomeGuild = () => import('../../components/home/guild/index.vue' /* webpackChunkName: "components/home-guild" */).then(c => wrapFunctional(c.default || c))
export const HomeGuildOther = () => import('../../components/home/guild/other.vue' /* webpackChunkName: "components/home-guild-other" */).then(c => wrapFunctional(c.default || c))
export const HomeGuildPc = () => import('../../components/home/guild/pc.vue' /* webpackChunkName: "components/home-guild-pc" */).then(c => wrapFunctional(c.default || c))
export const HomeIdoHot = () => import('../../components/home/ido/hot.vue' /* webpackChunkName: "components/home-ido-hot" */).then(c => wrapFunctional(c.default || c))
export const HomeIdo = () => import('../../components/home/ido/index.vue' /* webpackChunkName: "components/home-ido" */).then(c => wrapFunctional(c.default || c))
export const HomeIdoItem = () => import('../../components/home/ido/item.vue' /* webpackChunkName: "components/home-ido-item" */).then(c => wrapFunctional(c.default || c))
export const HomeIdoNewitem = () => import('../../components/home/ido/newitem.vue' /* webpackChunkName: "components/home-ido-newitem" */).then(c => wrapFunctional(c.default || c))
export const HomeNewgameContainer = () => import('../../components/home/newgame/container.vue' /* webpackChunkName: "components/home-newgame-container" */).then(c => wrapFunctional(c.default || c))
export const HomeNewgame = () => import('../../components/home/newgame/index.vue' /* webpackChunkName: "components/home-newgame" */).then(c => wrapFunctional(c.default || c))
export const HomeNewgameItem = () => import('../../components/home/newgame/item.vue' /* webpackChunkName: "components/home-newgame-item" */).then(c => wrapFunctional(c.default || c))
export const HomeInvestor = () => import('../../components/home/investor/index.vue' /* webpackChunkName: "components/home-investor" */).then(c => wrapFunctional(c.default || c))
export const HomeInvestorPc = () => import('../../components/home/investor/pc.vue' /* webpackChunkName: "components/home-investor-pc" */).then(c => wrapFunctional(c.default || c))
export const HomeSearch = () => import('../../components/home/search/index.vue' /* webpackChunkName: "components/home-search" */).then(c => wrapFunctional(c.default || c))
export const HomeStudio = () => import('../../components/home/studio/index.vue' /* webpackChunkName: "components/home-studio" */).then(c => wrapFunctional(c.default || c))
export const HomeStudioPc = () => import('../../components/home/studio/pc.vue' /* webpackChunkName: "components/home-studio-pc" */).then(c => wrapFunctional(c.default || c))
export const HomeTabs = () => import('../../components/home/tabs/index.vue' /* webpackChunkName: "components/home-tabs" */).then(c => wrapFunctional(c.default || c))
export const HomeToken = () => import('../../components/home/token/index.vue' /* webpackChunkName: "components/home-token" */).then(c => wrapFunctional(c.default || c))
export const HomeTokenPc = () => import('../../components/home/token/pc.vue' /* webpackChunkName: "components/home-token-pc" */).then(c => wrapFunctional(c.default || c))
export const HomeTop = () => import('../../components/home/top/index.vue' /* webpackChunkName: "components/home-top" */).then(c => wrapFunctional(c.default || c))
export const HomeTrend = () => import('../../components/home/trend/index.vue' /* webpackChunkName: "components/home-trend" */).then(c => wrapFunctional(c.default || c))
export const HomeTrendItem = () => import('../../components/home/trend/item.vue' /* webpackChunkName: "components/home-trend-item" */).then(c => wrapFunctional(c.default || c))
export const HomeWhale = () => import('../../components/home/whale/index.vue' /* webpackChunkName: "components/home-whale" */).then(c => wrapFunctional(c.default || c))
export const HomeWhaleTable = () => import('../../components/home/whale/table.vue' /* webpackChunkName: "components/home-whale-table" */).then(c => wrapFunctional(c.default || c))
export const HomeWhalechange = () => import('../../components/home/whalechange/index.vue' /* webpackChunkName: "components/home-whalechange" */).then(c => wrapFunctional(c.default || c))
export const HomeWhaledeal = () => import('../../components/home/whaledeal/index.vue' /* webpackChunkName: "components/home-whaledeal" */).then(c => wrapFunctional(c.default || c))
export const HomeWhaledealPc = () => import('../../components/home/whaledeal/pc.vue' /* webpackChunkName: "components/home-whaledeal-pc" */).then(c => wrapFunctional(c.default || c))
export const HomeTrendFollower = () => import('../../components/home/trend/follower/index.vue' /* webpackChunkName: "components/home-trend-follower" */).then(c => wrapFunctional(c.default || c))
export const HomeTrendFollowerPc = () => import('../../components/home/trend/follower/pc.vue' /* webpackChunkName: "components/home-trend-follower-pc" */).then(c => wrapFunctional(c.default || c))
export const HomeTrendKol = () => import('../../components/home/trend/kol/index.vue' /* webpackChunkName: "components/home-trend-kol" */).then(c => wrapFunctional(c.default || c))
export const HomeTrendKolPc = () => import('../../components/home/trend/kol/pc.vue' /* webpackChunkName: "components/home-trend-kol-pc" */).then(c => wrapFunctional(c.default || c))
export const HomeTrendInvestor = () => import('../../components/home/trend/investor/index.vue' /* webpackChunkName: "components/home-trend-investor" */).then(c => wrapFunctional(c.default || c))
export const HomeTrendInvestorPc = () => import('../../components/home/trend/investor/pc.vue' /* webpackChunkName: "components/home-trend-investor-pc" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
