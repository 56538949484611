export default ({ app: { router }, store }) => {
  router.afterEach((to, from) => {
    /* 告诉增加一个PV */
    try {
      //baidu
      if (location.hostname == 'localhost') return
      window._hmt = window._hmt || []
      window._hmt.push(['_trackPageview', to.fullPath])

      //google
      // window.dataLayer = window.dataLayer || [];
      // function gtag() { dataLayer.push(arguments); }
      // gtag('js', new Date());

      // gtag('config', 'G-ZGZE7RK7VT');
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'UA-123278381-2');

    } catch (e) { }
  })
}
